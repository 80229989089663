export default {
    /**
     *  Loader constants
     */
    loader_title: "Please do not close or refresh this page.",
    loader_body: "Please wait a moment while we securely redirect you to the payment page.",

    /**
     * Payment Method
     */
    payment_method_title: "Bayad Payment Gateway",
    payment_method_select_header: "Select Payment Method",
    privacy_policy: "Privacy Policy",
    terms_condition: "Terms & Conditions",
    signature: "Powered by",
    return_to: "< Return to ",

    /**
     * Payment Summary
     */
    payment_summary_header: "Payment Summary",
    payment_summary_amount_due: "Amount Due",
    payment_summary_fee: "Convenience Fee",
    payment_summary_total: "Total Amount",

    /**
     * Update Modal
     */
    update_modal_header: "Are you sure?",
    update_modal_body1: "This means you will be cancelling this transaction.",
    update_modal_body2: "Do you want to return to the merchant's page?",
    update_modal_confirm: "Yes",
    update_modal_cancel: "No",

    /**
     * Status Success
     */
    status_details_text: "View Details",
    status_redirect_text: "Back to Merchant",
    status_redirect_count: "You will be redirected back to the merchant's page after",
    status_success: "success",
    status_success_header: "Payment Successful!",
    status_success_sub_header: "Thank you for your payment.",

    /**
     * Success Payment Receipt
     */
    success_header_bayad: "Bayad - ",
    success_header_trans_info: "Transaction Info:",
    success_body_status: "Status: ",
    success_body_status_paid: "Paid",
    success_body_datetime: "Date and Time: ",
    success_body_ref_no: "Ref. No.: ",
    success_body_payment_method: "Payment Method: ",
    success_body_amount: "Amount: ",
    success_body_convenience_fee: "Convenience Fee: ",
    success_body_total_amount: "Total Amount: ",
    success_body_sub_amount: "Subtotal: ",
    success_body_note: "Note: Charge will appear on your statement of account as BAYAD Merchant.",
    success_redirect_text: "Back to ",
    success_body_biller_name: "Merchant: ",
    success_body_account_number: "Customer Account No.: ",
    success_body_donation_name: "Donation Name:",
    success_body_product_name:"Product Name:",

    /**
     * Status Failed
     */
    status_failed: 'failed',
    status_failed_header: "Something's not right...",
    status_failed_header1: "Payment Unsuccessful!",
    status_failed_header2: "Oops!",
    status_failed_title_non_issuer: "Sorry we’re experiencing some issues at the moment. Please try again after a few minutes.",
    status_failed_qr_session_expired: "Looks like your session has expired. Kindly try again.",
    status_failed_body1: "We encountered a problem with your card, and we are unable to proceed with your payment.",
    status_failed_body2: "Please contact your bank or e-wallet provider for assistance and try again.",
    status_failed_body3: "Please contact your bank for assistance and try again.",
    statue_failed_issuer: "We encountered a problem and we are unable to proceed with this payment",
    statue_failed_issuer2: "Sorry we are unable to proceed with your payment.",
    status_failed_non_issuer: "Bayad Checkout encountered an error. Don't worry, you can try transacting again.",
    status_failed_error_code: "Error Code: ",
    status_failed_ref_no: "Reference Number: ",
    status_failed_copy_code: "Copied to clipboard",
    status_failed_datetime: "Date and Time:",
    status_failed_title1: "We’re experiencing some issues at the moment.",
    status_failed_title2: "Please try again after a few minutes.",
    status_failed_invalid_card_security_code: "The card details you provided do not match. Kindly try again.",
    status_failed_invalid_authorization: "Seems like your transaction was not authorized by your bank. Kindly contact your bank for assistance and try again.",
    status_failed_declined_payment: "Sorry, but we are unable to proceed with your payment. Kindly contact your bank for assistance and try again.",
    status_failed_insuffucuent_funds: "You don’t have enough funds to proceed with this transaction. You could either add funds or change your payment method.",
    /**
     * Payment Types
     */
    payment_success: "SUCCESS",
    payment_failed: "FAILED",
    payment_canceled: "CANCELLED",
    payment_expired: "EXPIRED",

    /**
     * 404
     */
    status_404_title: "Continue with Checkout?",
    status_404_body: " Your current payment session has ended. Head back to your merchant's page so you can continue. ",

    /**
     * Session Timeout
     */
    timeout_header: "Continue with Checkout?",
    timeout_body1: "Your current payment session has ended.",
    timeout_body2: " Head back to your merchant's page so you can continue.",
    timeout_redirect_text: "Okay, bring me back",

    /**
     * Transaction Expired
     */
    expired_header: "Try Again.",
    expired_body: "We are unable to process your transaction at this time. We will redirect you back to the merchant's payment page so you can try again.",
    expired_redirect_text: "Okay, let's go!",

    from_otp_action_text: "Back To Merchant",
    from_otp_body_text: "Clicking the back button means your payment session needs to restart. Don't worry, no charges were made on your card.",
    from_otp_header_text: "Uh-oh!",

    from_end_action_text: "Okay",
    from_end_body_text: "Clicking the back button will redirect you to the merchant's page. Don't forget to check your payment status with the merchant.",
    from_end_header_text: "Going back to Merchant Page",

    block_title: "Hold On!",
    block_body: "This payment page is already active in another window. Please close this window and resume your previous session to proceed with your transaction.",

    maintenance_mode_header: "This site is currently under maintenance.",
    maintenance_mode_body: "We are making enhancements right now to improve your payment experience.",
    maintenance_mode_body1: "We will be back as soon as possible.",
    maintenance_mode_body2: "Please check again in a little while. Thank you.",

    /**
     * Updating Payment
     */
    updating_payment_body: "Please wait a moment while we redirect you to our acknowledgement payment page.",

    /**
     * Payment Duplicate Prompt
     */

    duplicate_prompt_notice: "We have successfully processed the following transaction and noticed that this might be a duplicate:",
    duplicate_prompt_account: "#merchant Customer Account Number: #accountNumber",
    duplicate_prompt_amount: "Amount: #amount",
    duplicate_prompt_date: "Date: #date",
    duplicate_prompt_proceed: "Do you want to proceed with this transaction?",
    duplicate_prompt_confirm: "Proceed",
    duplicate_prompt_back: "Cancel",

    /**
     * Wallet Proceed Modal
     */
    wallet_modal_proceed_action_text: "Proceed",
    wallet_modal_proceed_body_text: "Clicking the back button will redirect you to the payment wallet's page.",
    wallet_modal_proceed_header_text: "Redirecting to #wallet Page",

    /**
     * Contact
     */
     contact_phone: "(02)8672-5777",
     contact_email: "support@bayad.com",
    
    /**
    * Exit Survey
    */
     survey_header: "Help us improve.",
     survey_body: "We're sorry that you're not pushing through with your transaction. May we know why?",
     survey_option1: "My preferred payment method is unavailable.",
     survey_option2: "I chose the wrong bill to pay.",
     survey_option3: "I don’t want to pay the convenience fee.",
     survey_option4: "Other reasons",
     survey_submit_text: "Submit",
    survey_skip_text: "Skip",
     
    /**
    * Card Scheme
    */
    card_scheme_visa: 'Visa',
    card_scheme_mastercard: 'Mastercard',
    card_scheme_jcb : 'JCB',

    /**
    * Card Scheme Valid Message
    */
    card_scheme_valid_msg : 'Invalid card scheme. We accept #card only.'
}

export const constantFormat = (data, message) => {
    var msg = message
    Object.keys(data).map(key => {
        msg = msg.replace('#' + key, data[key])
    })
    return msg;
}