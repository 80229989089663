import { useSelector } from "react-redux";
import {
    CANCEL_PAYMENT,
    GET_PAYMENT,
    GET_PAYMENT_PROCESSOR,
    UPDATE_PAYMENT,
    WATCH_PAYMENT,
    SET_PROCESSOR,
    PAYMENT_WALLET_CREATE,
    SET_FORM_VALUES,
    CHECK_TRANSACTION_STATUS,
    INVALIDATE_PAYMENT,
} from "./PaymentTypes";



export const getPayment = (paymentId, isUpdate = false, checkDuplicate = false) => {
    return {
        type: GET_PAYMENT,
        payload: { paymentId, checkDuplicate },
        isUpdate: isUpdate
    };
}

export const setProcessor = (processorData) => {
    return {
        type: SET_PROCESSOR,
        payload: processorData
    };
}


export const updatePayment = (paymentData, status, sessionId, sessionExpired = false, errorCode = null) => {
    let data = {
        ...paymentData,
        status
    }

    if (status == 'SUCCESS') {
        data = {
            ...data,
            status: 'SUCCESS'
        }
    } else if (status == 'FAILED') {
        if (errorCode) {
            data = {
                ...data,
                errorCode: errorCode
            }
        }
    }

    if (sessionId) data.sessionId = sessionId

    if (sessionExpired) data.sessionExpired = sessionExpired

    return {
        type: UPDATE_PAYMENT,
        data
    }
}

export const cancelPayment = (paymentId, status) => {
    return {
        type: CANCEL_PAYMENT,
        paymentId,
        status
    };
}

export const getProcessor = (amount, paymentType, processor, paymentId) => {
    return {
        type: GET_PAYMENT_PROCESSOR,
        amount,
        paymentType,
        processor,
        paymentId
    }
}

export const watchPayment = (paymentId, checkDuplicate = false) => {
    return {
        type: WATCH_PAYMENT,
        paymentId,
        checkDuplicate
    }
}

export const createPaymentWallet = (wallet, paymentId, methodId, emailAddress, isMobile) => {
    let data = {
        type: PAYMENT_WALLET_CREATE,
        wallet,
        paymentId,
        methodId,
        isMobile: isMobile
    }

    if (emailAddress) data.emailAddress = emailAddress

    return data
}

export const setFormValues = (values, isValid) => {
    return {
        type: SET_FORM_VALUES,
        payload: {
            values,
            isValid
        }
    }
}

export const checkTransactionStatus = (wallet, paymentId) => {
    return {
        type: CHECK_TRANSACTION_STATUS,
        wallet,
        paymentId,
    }
}

export const invalidatePayment = (wallet, paymentId, isMobile) => {
    return {
        type: INVALIDATE_PAYMENT,
        wallet,
        paymentId,
        isMobile,
    }
}