import { all, delay, put, select, takeEvery, takeLatest} from "@redux-saga/core/effects";
import processAction from "../../utils/ProcessActions";
import { processCustomAction } from "../../utils/ProcessActions";
import {
    CANCEL_PAYMENT,
    CANCEL_PAYMENT_FAILED,
    CANCEL_PAYMENT_SUCCESS,
    DUPLICATE_DATA,
    GET_PAYMENT,
    GET_PAYMENT_FAILED,
    GET_PAYMENT_PROCESSOR,
    GET_PAYMENT_PROCESSOR_FAILED,
    GET_PAYMENT_PROCESSOR_SUCCESS,
    GET_PAYMENT_SUCCESS,
    PAYMENT_WALLET_CREATE,
    PAYMENT_WALLET_CREATE_FAILED,
    PAYMENT_WALLET_CREATE_SUCCESS,
    UPDATE_PAYMENT,
    UPDATE_PAYMENT_FAILED,
    UPDATE_PAYMENT_SUCCESS,
    WATCH_PAYMENT,
    CHECK_TRANSACTION_STATUS,
    CHECK_TRANSACTION_STATUS_SUCCESS,
    CHECK_TRANSACTION_STATUS_FAILED,
    INVALIDATE_PAYMENT,
    INVALIDATE_PAYMENT_SUCCESS,
    INVALIDATE_PAYMENT_FAILED,
} from "./PaymentTypes";
import * as selectors from '../selectors'

import {
    getPayment as getPaymentService,
    updatePayment as updatePaymentService,
    getProcessor as getProcessorService,
    createPaymentWallet as createPaymentWalletService,
    checkTransactionStatus as checkTransactionStatusService,
    invalidatePayment as invalidatePaymentService,
} from './PaymentServices'
import history from "../../utils/history";
import { GET_AUTH } from "../Auth/AuthTypes";

function* getPayment({ payload }) {

    yield processCustomAction({
        params: payload,
        service: getPaymentService,
        success: GET_PAYMENT_SUCCESS,
        failed: GET_PAYMENT_FAILED
    })

    let paymentData = yield select(selectors.paymentData)
    let paymentError = yield select(selectors.paymentError)

    if (paymentError.flag && paymentError.code === 20002 && paymentError.status === 422) {
        history.push('/multiple-browser-detected')
    } else if (paymentError.flag) {
        history.push('/404')
    }

    if (paymentData && !payload.isUpdate) {
        if (paymentData.status === "PENDING") {
            if (payload.checkDuplicate) {
                yield put({
                    type: DUPLICATE_DATA,
                    payload: paymentData.warningPrompt
                })
            }
            history.push('/transaction')
        } else {
            history.push(`/404`)
        }
    }

}

function* updatePayment({ data }) {

    let auth = yield select(selectors.token)
    let body = {
        data: data,
        auth: auth
    }
    yield processAction({
        params: body,
        service: updatePaymentService,
        success: UPDATE_PAYMENT_SUCCESS,
        failed: UPDATE_PAYMENT_FAILED
    })
}

function* getProcessor({ amount, paymentType, processor, paymentId }) {
    const actions = Object.values({ amount, paymentType, processor, paymentId }).map(item => item);
    yield processAction({
        params: [...actions],
        service: getProcessorService,
        success: GET_PAYMENT_PROCESSOR_SUCCESS,
        failed: GET_PAYMENT_PROCESSOR_FAILED
    })
}

function* watchPayment({ paymentId, checkDuplicate }) {
    const payload = { paymentId, checkDuplicate }
    yield processAction({
        params: payload,
        service: getPaymentService,
        success: GET_PAYMENT_SUCCESS,
        failed: GET_PAYMENT_FAILED
    })

    // let paymentData = select(selectors.paymentData)

    yield delay(5000)

    yield put({
        type: WATCH_PAYMENT,
        paymentId: paymentId,
        checkDuplicate: checkDuplicate
    })

}

function* createPaymentWallet({ wallet, paymentId, methodId, emailAddress, isMobile }) {
    let data = { wallet, paymentId, methodId, isMobile }

    if (emailAddress) data.emailAddress = emailAddress

    const actions = Object.values(data).map(item => item);
    
    yield processAction({
        params: [...actions],
        service: createPaymentWalletService,
        success: PAYMENT_WALLET_CREATE_SUCCESS,
        failed: PAYMENT_WALLET_CREATE_FAILED
    })
}


function* checkTransactionStatus({ wallet, paymentId }) {
    let data = { wallet, paymentId }

    const actions = Object.values(data).map(item => item);

    yield processAction({
        params: [...actions],
        service: checkTransactionStatusService,
        success: CHECK_TRANSACTION_STATUS_SUCCESS,
        failed: CHECK_TRANSACTION_STATUS_FAILED
    })
}

function* invalidatePayment({ wallet, paymentId, isMobile }) {
    let data = { wallet, paymentId, isMobile }

    const actions = Object.values(data).map(item => item);

    yield processAction({
        params: [...actions],
        service: invalidatePaymentService,
        success: INVALIDATE_PAYMENT_SUCCESS,
        failed: INVALIDATE_PAYMENT_FAILED
    })
}


export default function* watchPaymentRequests() {
    yield all([
        takeEvery(GET_PAYMENT, getPayment),
        takeEvery(UPDATE_PAYMENT, updatePayment),
        takeLatest(GET_PAYMENT_PROCESSOR, getProcessor),
        takeEvery(WATCH_PAYMENT, watchPayment),
        takeEvery(PAYMENT_WALLET_CREATE, createPaymentWallet),
        takeEvery(CHECK_TRANSACTION_STATUS, checkTransactionStatus),
        takeEvery(INVALIDATE_PAYMENT, invalidatePayment),
    ])
}