import {
    Grid,
    makeStyles,
    Typography,
    CssBaseline,
    Container,
    Popover,
    Paper,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React, { useEffect, useState, createRef } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import moment from 'moment';
import constant from '../../../../utils/Constants';
import Sticky from 'react-sticky-el';
import PrivacyAlert from '../../../../components/PrivacyAlert/PrivacyAlert';


const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        [theme.breakpoints.down("xs")]: {
            marginBottom: 0,
            paddingBottom: 10,
            paddingRight: 0,
            paddingLeft: 0,
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
            paddingBottom: 10,
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
    paymentSummaryContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },

    displayName: {
        marginBottom: 15,
    },

    logo: {
        maxHeight: 70,
        marginBottom: 10,
        marginTop: 10
    },

    summary: {
        margin: 0,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
            maxWidth: '100%',
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
            maxWidth: '100%',
        },
    },

    sticky: {
        backgroundColor: '#bbb'
    },

    secondPanel: {
        flexWrap: 'nowrap',
        paddingTop: 5,
        marginTop: theme.spacing(1),
    },

    thirdPanel: {
        paddingTop: 5
    },

    paymentSummaryTitle: {
        paddingTop: 15,
        paddingBottom: 15,
    },
    paymentDetailsTitle: {
        fontColor: '#444444',
        fontWeight: 600,
    },
    paymentDetails: {
        fontColor: '#444444',
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverIcon: {
        verticalAlign: 'bottom',
        marginLeft: 5,
        color: '#444444',
        [theme.breakpoints.down("xs")]: {
            width: 15,
            verticalAlign: 'middle',
        },
    },
    paper: {
        padding: theme.spacing(1.5),
        backgroundColor: '#0076bf'
    },
    amount: {
        textAlign: 'right',
    },
    refnum: {
        textAlign: 'left',
    },
    paperContainer: {
        borderRadius: 7,
        border: "1px solid #dbdbdb",
        [theme.breakpoints.up("lg")]: {
            maxHeight: '27rem',
        },
        [theme.breakpoints.only("md")]: {
            maxHeight: '27.5rem',
        },
        [theme.breakpoints.up("md")]: {
            overflow: 'auto',
            "&::-webkit-scrollbar": {
                width: 5
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "#E6E6E6"
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#BFBDBD",
                borderRadius: 2
            }
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
            border: 0,
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            border: 0,
        },
    },
    buttonContainer: {
        margin: 'auto'
    },
    dashed: {
        padding: 10,
        borderBottom: "1px dashed rgba(0, 0, 0, 0.25)"
    },
    currency: {
        verticalAlign: "top",
        fontSize: 15,
        marginRight: 5
    },
    back: {
        cursor: 'pointer',
        textDecoration: 'none'
    },
    backButton: {
        marginBottom: 10,
        paddingRight: 25,
        justifyContent: 'right',
    },
    stickyTextPaymentSummary: {
        marginTop: 2,
        letterSpacing: '0.78px',
        fontWeight: 'bold',
    },
    stickyTextTotalAmount: {
        letterSpacing: '0.69px',
        color: '#444444',
        textTransform: 'uppercase',
    },
}))


const PaymentSummary = (props) => {
    const classes = useStyles();
    const payment = useSelector(state => state.payment)
    const ref = createRef()
    const theme = useTheme()
    const { handleModal, backModal} = props
    const matches_xs = useMediaQuery(theme.breakpoints.down('xs'))
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))
    const [stuck, setStuck] = useState(false)
    const [summary, setSummary] = useState({});
    const [totalAmount, setTotalAmount] = useState('0000')
    const [merchantName, setMerchantName] = useState('')
    const [bankFee, setBankFee] = useState({
        value: 0,
        currency: 'PHP'
    });

    useEffect(() => {
        if (payment.data) {
            setSummary(payment.data)
            setTotalAmount(payment.data.totalAmount && payment.data.totalAmount.value)
            if (payment.data.merchantName.toUpperCase() === "BAYAD ONLINE") {
                setMerchantName(payment.data.merchantName)
            } else {
                setMerchantName("BAYAD - " + payment.data.merchantName)
            }
        }
        if (payment.processor && !props.resetBankFee) {
            setBankFee({
                ...bankFee,
                value: payment.processor.fee
            })
        } else {
            setBankFee({
                value: 0,
                currency: 'PHP'
            })
        }
    }, [payment])

    useEffect(() => {
        if (summary.totalAmount) {
            setTotalAmount(+totalAmount + +bankFee.value)
        }
    }, [bankFee])

    useEffect(() => {
        const cachedRef = ref.current
        const observer = new IntersectionObserver(
            ([e]) => setStuck(e.intersectionRatio < 1),
            { threshold: [1] }
        )
        observer.observe(cachedRef)
        return () => observer.unobserve(cachedRef)
    }, [ref])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Container maxWidth="lg" className={classes.paymentSummaryContainer}>
            <PrivacyAlert id='page-privacy-alert' handleModal={handleModal}></PrivacyAlert>
                <Paper className={classes.paperContainer} elevation={0}>

                    <Container className={classes.root}>

                        <CssBaseline />
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            md={12}
                        >
                            <Grid item>
                                <img src={summary.merchantLinks && summary.merchantLinks.logo} className={classes.logo}></img>
                            </Grid>
                            <Grid item className={classes.displayName}>
                                <Typography >{merchantName}</Typography>
                            </Grid>
                        </Grid>


                        <Grid
                            container
                            direction="column"
                            columnSpacing={1}
                            rowSpacing={3}
                            md={12}
                            className={classes.summary}
                        >
                            <Grid item>
                                <Typography variant="h5" className={classes.paymentSummaryTitle}>{constant.payment_summary_header.toUpperCase()}</Typography>
                            </Grid>

                            <Grid item >
                                <Typography variant="h6" className={classes.paymentDetailsTitle}>{summary.merchantLinks && summary.merchantLinks.displayName} Payment Details:</Typography>
                                {
                                    summary.items && summary.items.map(content => {
                                        if (summary.merchantCustomerInformation.itemColumns === 2) {
                                            return <>
                                                <Grid item>
                                                    {
                                                        content.details.map(details => {
                                                            return <Grid container justify="space-between" className={classes.secondPanel} >
                                                                <Grid item >
                                                                    <Typography variant="h6" className={classes.paymentDetails}>{details.label}</Typography>
                                                                </Grid>
                                                                <Grid item >
                                                                    <Typography variant="h6" className={classes.paymentDetails}>{moment(details.value, 'YYYY-MM-DD', true)._isValid ? moment(details.value).format('MMMM D, YYYY') : details.value}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        })
                                                    }
                                                </Grid>
                                            </>
                                        } else {
                                            return <>
                                                <Grid item>
                                                    {
                                                        content.details.map(details => {
                                                            return <Grid container justify="space-between" className={classes.thirdPanel} >
                                                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                                                    <Typography variant="h6" className={classes.paymentDetails}>{details.label}</Typography>
                                                                </Grid>
                                                                <Grid item className={classes.refnum} xs={6} sm={6} md={4} lg={4}>
                                                                    <Typography variant="h6" className={classes.paymentDetails}>{moment(details.value, 'YYYY-MM-DD', true)._isValid ? moment(details.value).format('MMMM D, YYYY') : details.value}</Typography>
                                                                </Grid>
                                                                <Grid item
                                                                    className={classes.amount}
                                                                    textAlign={'right'}
                                                                    xs={6} sm={6} md={3} lg={3}
                                                                // width={matches ? '100px' : '150px'}
                                                                >
                                                                    <Typography variant="h6">
                                                                        <NumberFormat
                                                                            value={content.amount}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            prefix={content.amount && summary.totalAmount.currency + ' '}
                                                                        />
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        })
                                                    }
                                                </Grid>

                                            </>
                                        }
                                    })
                                }

                                <Grid item className={`${classes.secondPanel}, ${classes.dashed}`}></Grid>

                                <Grid item >
                                    <Grid container justify="space-between">
                                        <Grid item className={classes.secondPanel}>
                                            <Typography variant="h6" className={classes.paymentDetails}>{constant.payment_summary_amount_due}</Typography>
                                        </Grid>
                                        <Grid item className={classes.secondPanel}>
                                            <Typography variant="h6" className={classes.paymentDetails}>
                                                <NumberFormat
                                                    value={summary.totalAmount && summary.totalAmount.value}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={summary.totalAmount && summary.totalAmount.currency + ' '}
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item>
                                    <Grid container justify="space-between">
                                        <Grid item className={classes.secondPanel}>
                                            <Typography variant="h6" className={classes.paymentDetails}>
                                                {constant.payment_summary_fee}
                                                <Info color="initial"
                                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen}
                                                    onMouseLeave={handlePopoverClose}
                                                    className={classes.popoverIcon}
                                                >
                                                </Info>
                                            </Typography>
                                            <Popover
                                                id="mouse-over-popover"
                                                className={classes.popover}
                                                classes={{
                                                    paper: classes.paper,
                                                }}
                                                open={open}
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                            >
                                                <Typography variant='caption'>The Convenience Fee indicated here goes directly to our payment partner.</Typography>
                                            </Popover>
                                        </Grid>
                                        <Grid item style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}
                                            className={classes.secondPanel}>
                                            <Typography variant="h6" className={classes.paymentDetails}>
                                                <NumberFormat
                                                    value={bankFee.value}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={bankFee.currency + ' '}
                                                />
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div id='div-sticky' ref={ref}>
                                    <Sticky id='sticky-payment-summary'>
                                        {
                                            (matches_xs || matches_sm) && stuck ?
                                                <Grid item id='grid-item-sticky-payment-summary'>
                                                    <Typography id='typography-sticky-payment-summary' variant="h4" className={classes.stickyTextPaymentSummary}>{constant.payment_summary_header.toUpperCase()}</Typography>
                                                </Grid>
                                            : <></>
                                        }
                                        <Grid item id='grid-item-second-panel' className={classes.secondPanel}>
                                            <Grid container id='grid-container-total-amount' justify="space-between" >
                                                <Grid item id='grid-item-total-amount'>
                                                    <Typography id='typography-total-amount-text' variant={(matches_xs || matches_sm) && stuck ? "h5" : "h4"} className={(matches_xs || matches_sm) && stuck ? classes.stickyTextTotalAmount : ""}>{constant.payment_summary_total}</Typography>
                                                </Grid>
                                                <Grid item id='grid-item-total-amount-value'>
                                                    <Typography id='typography-total-amount-value' variant="h3" color='white'>
                                                        <NumberFormat
                                                            id='number-format-total-amount-value'
                                                            value={totalAmount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            renderText={(value, props) =>
                                                                <div >
                                                                    <a className={classes.currency}>PHP</a>
                                                                    {value}
                                                                </div>
                                                            }
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Sticky>
                                </div>
                            </Grid>

                        </Grid>

                    </Container >
                </Paper>
            </Container>
            {props.setTotalAmount(totalAmount)}
        </div >
    );
}

export default PaymentSummary;