import { Button, Card, Container, Divider, Grid, makeStyles, Typography, Snackbar, useMediaQuery, Popover } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTheme } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import successLogo from '../../../../components/assets/success.png'
import masterCardLogo from '../../../../assets/icons/masterCardLogo.svg'
import gcashLogo from '../../../../assets/icons/gcashLogo.svg'
import paymayaLogo from '../../../../assets/icons/mayaLogo.svg'
import bayadAppLogo from '../../../../assets/icons/bayadAppLogo.svg'
import jcbLogo from '../../../../assets/icons/jcbLogo.svg'
import visaLogo from '../../../../assets/icons/visaLogo.svg'
import shopeepayLogo from '../../../../assets/icons/shopeepayLogo.svg'
import backToMerchantLogo from '../../../../components/assets/successLogo.png'
import constant from '../../../../utils/Constants';
import urlEncode from "../../../../utils/UrlEncode";
import moment from 'moment';
import ModalBase from "../../../../components/ModalBase/ModalBase";
import Header from "../../../../components/Header/Header";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '95vh',
        //margin: 'auto',
        textAlign: 'center',
    },
    headerContainer: {
        paddingBottom: 30,
    },
    successBodyContainer: {
        padding: "30px 15px 30px 15px",
        maxWidth: 600,
        width: 600,
        [theme.breakpoints.down("xs")]: {
            width: '100%',
        }
    },
    successLogo: {
        width: 148,
        height: 133,
        marginLeft: -15
    },
    bayadLogo: {

    },
    footerContainer: {
        paddingTop: 30,
    },
    btnAction: {
        letterSpacing: '0.69px',
        font: 'normal normal normal 14px/21px Poppins',
        width: '224px',
        height: '42px',
        borderRadius: '21px',
        fontWeight: 'bold',
    },
    bodyValue: {
        wordWrap: "break-word",
        font: 'normal normal normal 14px/30px Poppins',
        letterSpacing: '0.69px',
        [theme.breakpoints.down("xs")]: {
            font: 'normal normal normal 12px/20px Poppins',
        }
    },
    bodyNote: {
        color: '#0076BF !important',
        letterSpacing: '0.39px',
        paddingTop: 15,
        font: 'normal normal normal 8px/14px Poppins',
        [theme.breakpoints.down("xs")]: {
            font: 'normal normal normal 6px/10px Poppins',
            marginTop: -10,
        }
    },
    logo: {
        width: '33px',
        height: '21px',
        marginBottom: -5,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '0.5px solid #BABECA',
        borderRadius: 2,
        [theme.breakpoints.down("xs")]: {
            width: '25px',
            height: '16px',
            marginBottom: -4,
        }
    },
    successBody: {
        background: '#F6F6F6 0% 0% no-repeat padding-box',
        marginLeft: 20,
        marginRight: 20,
        backgroundImage:'url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216px%22%20height%3D%228px%22%3E%3Cpolygon%20points%3D%220%2C0%208%2C8%2016%2C0%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216px%22%20height%3D%228px%22%3E%3Cpolygon%20points%3D%220%2C8%208%2C0%2016%2C8%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E")',
        backgroundPosition: 'top, bottom',
        backgroundRepeat: 'repeat-x',
        [theme.breakpoints.down("xs")]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    btnViewDetais: {
        letterSpacing: '0.69px',
        font: 'normal normal normal 14px/21px Poppins',
        width: '224px',
        height: '42px',
        borderRadius: '21px',
        borderColor: '#0077C0',
    },
    totalAmountValue: {
        font: 'normal normal 900 18px/30px Poppins',
        letterSpacing: '0.88px',
        color: '#0077C0', 
        marginTop: -10,
        [theme.breakpoints.down("xs")]: {
            font: 'normal normal 900 14px/20px Poppins',
            marginTop: 0,
        }
    },
    totalAmountCurrency:{
        font: 'normal normal 900 11px/0px Poppins',
        letterSpacing: '0.88px',
        [theme.breakpoints.down("xs")]: {
            font: 'normal normal 900 7px/0px Poppins',
        }
    },
    totalAmount: {
        font: 'normal normal 900 16px/30px Poppins',
        letterSpacing: '0.88px',
        textTransform: 'uppercase',
        color: '#2A3033',
        [theme.breakpoints.down("xs")]: {
            font: 'normal normal 900 14px/20px Poppins',
        }
    },

    counterBody:{
        font: 'normal normal normal 10px/16px Poppins',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#58595B',
        [theme.breakpoints.down("xs")]: {
            font: 'normal normal normal 8.5px/16px Poppins',
        }
    },
    successSubHeader: {
        font: 'normal normal normal 12px/30px Poppins',
        letterSpacing: '0.59px',
        fontWeight: 'bold',
    },
}));



const AcknowledgementSuccess = (props) => {
    const classes = useStyles();
    const query = useQuery();
    const [counter, setCounter] = useState(10);
    const history = useHistory()
    const [logo, setLogo] = useState()
    const [cardNumber, setCardNumber] = useState("");
    const [merchantName, setMerchantName] = useState('')
    const [redirectOption, setRedirectOption] = useState(true)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [payMoreBills, setPayMoreBills] = useState(false);
    const [payMoreBillsOnly, setPayMoreBillsOnly] = useState(false);
    const [accountDetails, setAccountDetails] = useState(false);

    const onClickRedirect = useCallback(() => {
        history.push(`/redirect?${urlEncode('q', props.merchantLink)}&exit=true`)
    })

    useEffect(() => {
        if (process.env.REACT_APP_MERCHANT_REDIRECT == 1) {
            if (window !== window.parent) {
                setRedirectOption(false)
            }
        }

    }, [])

    useEffect(() => {
        if (redirectOption) {
            if (counter > 0) {
                setTimeout(() => setCounter(counter - 1), 1000);
            }
            else {
                if (props.redirect && !props.isBackButtonClicked)
                    onClickRedirect();
            }
        }
    })

    useEffect(() => {
        if (props.paymentUpdate.merchantLinks.displayName.toUpperCase() === "BAYAD ONLINE") {
            setMerchantName(props.paymentUpdate.merchantLinks.displayName)
        } else {
            setMerchantName(constant.success_header_bayad + props.paymentUpdate.merchantLinks.displayName)
        }

        switch (props.paymentUpdate.reference.scheme) {
            case 'visa':
            case 'VISA':
                setLogo(visaLogo)
                break;
            case 'jcb':
                setLogo(jcbLogo)
                break;
            case 'master-card':
            case 'MASTERCARD':
                setLogo(masterCardLogo)
                break;
            case 'paymaya-wallet':
                setLogo(paymayaLogo)
                break;
            case 'gcash-wallet':
                setLogo(gcashLogo)
                break;
            case 'bayad-wallet':
                setLogo(bayadAppLogo)
                break;
            case 'shopeepay-wallet':
                setLogo(shopeepayLogo)
                break;
        }
        setCardNumber(props.paymentUpdate.reference.scheme.includes('wallet') ? "" : props.paymentUpdate.reference.maskedCardNumber.replace(/\s/g, '').substr(-5))
    }, [])

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.paymentUpdate.showPayMore) {
            setPayMoreBills(true)
        }  

        if (props.paymentUpdate.showPayOnly) {
            setPayMoreBillsOnly(true)
        }

        if (props.paymentUpdate.accountDetails.length > 0) {
            setAccountDetails(true)
        }
    }, [])

    const checkAccountNumber = (value) => {
        let accountNumber = value.replace(/\s+/g, '')
        let isAccountValid = accountNumber.length > 0 ? true : false
        return isAccountValid 
    }

    const setAccountLabelByMerchant = (merchant) => {
        let labelDisplay = ""
        switch (merchant) {
            case "One Meralco Foundation":
                labelDisplay = constant.success_body_donation_name
                break;
            case "TGLM":
                labelDisplay = constant.success_body_product_name
                break;
            //add case for other merchant
            default:
                labelDisplay = constant.success_body_account_number
                break;
        }
        return labelDisplay
    }
    
    return (
        <Container class='containerBg'>
            {
                !matches ? <Header/> : <></>
            }
            <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.root}
            >
                <Grid item>
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                        spacing={2}
                        className={classes.headerContainer}
                    >
                        <Grid item>
                            <img className={classes.successLogo} src={successLogo}></img>
                        </Grid>
                        <Grid item>
                            <Typography  variant='h5'>
                                {constant.status_success_header}
                            </Typography>
                            <Typography variant='h6' style={{paddingTop: 10, fontWeight: 600}}>
                                {constant.status_success_sub_header}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11}>
                    {!show && <Button variant='outlined' color="primary" className={classes.btnViewDetais}   onClick={() => setShow(prev => !prev)} >
                        {props.redirectLabel ? props.redirectLabel : constant.status_details_text}
                    </Button>}
                    {show && <Grid item className={classes.successBody}>
                            <Grid
                                container
                                className={classes.successBodyContainer}
                                direction='column'
                                spacing={1}
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item xs={6}>
                                            <Typography align='left' className={classes.bodyValue}>{constant.success_body_status}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='right' className={classes.bodyValue}>{constant.success_body_status_paid}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography align='left' className={classes.bodyValue}>{constant.success_body_datetime}</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography align='right' className={classes.bodyValue}>{props.paymentUpdate && moment(props.paymentUpdate.updatedAt).format('LLL')}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='left' className={classes.bodyValue}>{constant.success_body_biller_name}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='right'className={classes.bodyValue}>{props.paymentUpdate && props.paymentUpdate.merchantName}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography align='left' className={classes.bodyValue}>{constant.success_body_ref_no}</Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography align='right' className={classes.bodyValue}>{props.paymentUpdate && props.paymentUpdate.referenceNumber}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='left' className={classes.bodyValue}>{constant.success_body_payment_method}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='right' className={classes.bodyValue}><img className={classes.logo} src={logo}></img> {cardNumber}</Typography>
                                        </Grid>
                                        { accountDetails ? 
                                                props.paymentUpdate.accountDetails.map(details => {
                                                    return <>
                                                    {   payMoreBillsOnly ? <>
                                                        <Grid item xs={6} >
                                                        <Typography align='left' className={classes.bodyValue}>{constant.success_body_biller_name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} >
                                                            <Typography align='right' className={classes.bodyValue}>{details.billerName}</Typography>
                                                        </Grid> </> : <></>
                                                    }
                                                    {checkAccountNumber(details.accountNumber) ?
                                                        <>
                                                            <Grid item xs={6} >
                                                                <Typography align='left' className={classes.bodyValue}>{payMoreBillsOnly ? constant.success_body_biller_account_number : setAccountLabelByMerchant(props.paymentUpdate.merchantName)}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} >
                                                                <Typography align='right' className={classes.bodyValue}>{details.accountNumber}</Typography>
                                                            </Grid>
                                                        </> :
                                                        <></>
                                                    }
                                                    { 
                                                        props.paymentUpdate.accountDetails.length > 1 ? <>
                                                        <Grid item xs={6} >
                                                        <Typography align='left' className={classes.bodyValue}>{constant.success_body_amount}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} >
                                                            <Typography align='right' className={classes.bodyValue}>PHP {Number(details.amount).toFixed(2)}</Typography>
                                                        </Grid> </> : <></>
                                                    } 
                                                    </>
                                        }): <></>}
                                        <Grid item xs={12}></Grid><Grid item xs={12}></Grid>
                                        {
                                            props.paymentUpdate.accountDetails.length > 1 ? 
                                                <><Grid item xs={6}>
                                                    <Typography align='left' className={classes.bodyValue}>{constant.success_body_sub_amount}</Typography>
                                                </Grid></> 
                                            :   <><Grid item xs={6}>
                                                <Typography align='left' className={classes.bodyValue}>{constant.success_body_amount}</Typography>
                                                </Grid></>
                                        }
                                        <Grid item xs={6}>
                                            <Typography align='right' className={classes.bodyValue}>PHP {props.paymentUpdate && props.paymentUpdate.totalAmount.value}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='left' className={classes.bodyValue}>{constant.success_body_convenience_fee}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='right' className={classes.bodyValue}>PHP {props.paymentUpdate && props.paymentUpdate.bankFee}</Typography>
                                        </Grid>
                                       
                                        <Grid item xs={6}>
                                            <Typography align='left' className={classes.totalAmount}>{constant.success_body_total_amount}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align='right' className={classes.totalAmountValue}><sup  className={classes.totalAmountCurrency}>PHP </sup>{props.paymentUpdate && (parseFloat(props.paymentUpdate.totalAmount.value) + parseFloat(props.paymentUpdate.bankFee)).toFixed(2)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item >
                                    <Typography align='left' className={classes.bodyNote}>{constant.success_body_note}</Typography>
                                </Grid>
                            </Grid>
                    </Grid>}
                    
                </Grid>
                <Grid item>
                    {
                        (redirectOption) ?
                            <Grid
                                container
                                direction='column'
                                justify='center'
                                alignItems='center'
                                spacing={2}
                                className={classes.footerContainer}
                            >
                                <Grid item>
                                    <Typography variant='subtitle1' className={classes.counterBody}>
                                        {constant.status_redirect_count} {counter}s.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button color="primary" className={classes.btnAction} onClick={() => onClickRedirect()} >
                                            <ArrowBack /> {constant.status_redirect_text}
                                    </Button>
                                </Grid>
                            </Grid>
                            : <></>
                    }
                </Grid>
            </Grid>
            <ModalBase open={props.isBackButtonClicked} setModalOpen={props.setBackbuttonPress} handleYesModal={onClickRedirect} logo={backToMerchantLogo} actionText={constant.from_end_action_text} bodyText={constant.from_end_body_text} headerText={constant.from_end_header_text}></ModalBase>
        </Container>
    )
}

export default AcknowledgementSuccess;