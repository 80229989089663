export const GET_PAYMENT = 'GET_PAYMENT';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILED = 'GET_PAYMENT_FAILED';

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILED = 'UPDATE_PAYMENT_FAILED';

export const PAYMENT_WALLET_CREATE = 'PAYMENT_WALLET_CREATE'
export const PAYMENT_WALLET_CREATE_SUCCESS = 'PAYMENT_WALLET_CREATE_SUCCESS'
export const PAYMENT_WALLET_CREATE_FAILED = 'PAYMENT_WALLET_CREATE_FAILED'

export const CANCEL_PAYMENT = 'CANCEL_PAYMENT'
export const CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS'
export const CANCEL_PAYMENT_FAILED = 'CANCEL_PAYMENT_FAILED'

export const SET_METHOD = 'SET_METHOD';
export const SET_REFERENCE = 'SET_REFERENCE';
export const SET_PROCESSOR = 'SET_PROCESSOR';
export const SET_BANKFEE = 'SET_BANKFEE';

export const CREATE_PAYMENT = "CREATE_PAYMENT"
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS"
export const CREATE_PAYMENT_FAILED = "CREATE_PAYMENT_FAILED"

export const GET_PAYMENT_PROCESSOR = "GET_PAYMENT_PROCESSOR"
export const GET_PAYMENT_PROCESSOR_SUCCESS = "GET_PAYMENT_PROCESSOR_SUCCESS"
export const GET_PAYMENT_PROCESSOR_FAILED = "GET_PAYMENT_PROCESSOR_FAILED"

export const WATCH_PAYMENT = 'WATCH_PAYMENT'

export const DUPLICATE_DATA = 'DUPLICATE_DATA'

export const SET_FORM_VALUES = "SET_FORM_VALUES"

export const CHECK_TRANSACTION_STATUS = 'CHECK_TRANSACTION_STATUS'
export const CHECK_TRANSACTION_STATUS_SUCCESS = 'CHECK_TRANSACTION_STATUS_SUCCESS'
export const CHECK_TRANSACTION_STATUS_FAILED = 'CHECK_TRANSACTION_STATUS_FAILED'

export const INVALIDATE_PAYMENT = 'INVALIDATE_PAYMENT'
export const INVALIDATE_PAYMENT_SUCCESS = 'INVALIDATE_PAYMENT_SUCCESS'
export const INVALIDATE_PAYMENT_FAILED = 'INVALIDATE_PAYMENT_FAILED'