import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { terminate } from '../../store/Auth/AuthAction';
import { Redirect, useLocation } from "react-router-dom";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const RedirectTo = (props) => {
    const dispatch = useDispatch();
    const query = useQuery();

    useEffect(() => {
        if (query.get('exit'))
            dispatch(terminate());

        console.log(query.get('q'))
        console.log(window.parent, 'parent')
        if (window.parent) {
            window.parent.location.href = query.get('q');
        } else {
            window.location.replace(query.get('q'));
        }
    }, [])

    return (
        <div></div>
    );
}

export default RedirectTo;
