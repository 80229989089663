import {
    Grid,
    InputAdornment,
    makeStyles,
    Typography,
    TextField,
    Popover,
    IconButton,
} from '@material-ui/core';
import { Formik, Form, Field, useFormikContext } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import {
    TextField as formikTextField
} from 'formik-material-ui'
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { Info, Visibility, VisibilityOff } from '@material-ui/icons';
import constants, { constantFormat } from '../../../../utils/Constants';
import { setFormValues } from '../../../../store/Payment/PaymentAction'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        // marginTop: 50,
        marginBottom: 23
    },
    label: {
        padding: theme.spacing(1), //grid padding
    },
    adjusted: {
        padding: theme.spacing(1), //grid padding
        minHeight: 34
    },
    actions: {
        paddingTop: theme.spacing(3),
    },

    loader: {
        color: '#fafafa'
    },

    back: {
        cursor: 'pointer',
        textDecoration: 'none'
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverIcon: {
        color: '#909090',
        width: 20,
        [theme.breakpoints.down("xs")]: {
            width: 15,
            marginLeft: -3,
        },
    },
    paper: {
        padding: theme.spacing(1.5),
        backgroundColor: '#0076bf'
    },
    cardIcons: {
        width: 45,
        height: 30,
        [theme.breakpoints.down("xs")]: {
            width: 40,
            height: 25,
        },
    },
    cardFormFields: {
        fontSize: '0.9em',
        [theme.breakpoints.down("xs")]: {
            height: '0.1em',
            fontSize: 10,
        },
    },
    maskButton: {
        cursor: 'pointer',
        color: '#8D8D8D',
        width: 23,
        height: 23,
        marginLeft: 4,
        [theme.breakpoints.down("xs")]: {
            width: 19,
            height: 19,
            marginRight: -4,
        },
    },

}));

const FormObserver = (props) => {
    const { values, isValid } = useFormikContext();
    const dispatch = useDispatch()
    useEffect(() => {
        let valid = Object.values(values).includes('') ? false : isValid
        props.handleFormChange(values, valid)
        // dispatch(setFormValues(values, valid))
    }, [values, isValid]);
    return null;
};



const PgwForm = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const formRef = useRef()
    const { disabled, payment, isPanelSelected } = props
    const [showCardDetails, setShowCardDetails] = useState(true)
    const [showCvvDetails, setShowCvvDetails] = useState(true)
    const [cardScheme, setCardScheme] = useState({})
    const [dateError, setDateError] = useState({
        'tag': false,
        'msg': ''
    })
   
    let validCardSchemeMsg = ""

    const [minimumYear, setMinimumYear] = useState(0)

    const [initialForm, setInitialForm] = useState({
        cardNumber: '',
        expiryDate: '',
        expiryMonth: 13,
        expiryYear: 0,
        cvc: '',
        firstName: '',
        lastName: '',
        isCardValid: false,
    })

    const setValidCardSchemeInlineMgs = () => {
        // SET INLINE MESSAGE FOR VALID CARD SCHEME
        if (payment) {
            let cards = payment.merchantPaymentOptions.cards
            let validCards = []
            let msg = ""

            Object.entries(cards).map(([key, value]) => {
                if(value) validCards.push(key)
            })
            if (validCards.length === 1) {
                msg = constants['card_scheme_'+validCards[0].toLowerCase()]
                validCardSchemeMsg = constantFormat({ card: msg }, constants.card_scheme_valid_msg)
            } else {
                validCards.map((value, index) => {
                    if (validCards.length === index + 1) {
                        msg = msg + "and " + constants['card_scheme_'+value.toLowerCase()];
                    } else {
                        msg = msg + constants['card_scheme_'+value.toLowerCase()] + ', ';
                    }
                })
                msg = msg.replace(', and', ' and')
            }
            
            validCardSchemeMsg = constantFormat({ card: msg }, constants.card_scheme_valid_msg)
        }
    }

    setValidCardSchemeInlineMgs();

    const [initialValidation, setInitialValidation] = useState({
        cardNumber: Yup.string().required('You missed this! Fill in the required details to proceed.').min(5, validCardSchemeMsg)
            .test(
                "card-valid-check",
                validCardSchemeMsg,
                value => {
                    // handleCheckCardScheme(value)
                    return initialForm.isCardValid
                }
            ),
        expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').max(12, 'Invalid Month'),
        expiryYear: Yup.number().required('You missed this! Fill in the required details to proceed.').max(99, 'Invalid Year'),
        expiryDate: Yup.string().required('You missed this! Fill in the required details to proceed.'),
        cvc: Yup.string().required('You missed this! Fill in the required details to proceed.').min(3, 'You missed this! Fill in the required details to proceed.').max(4, 'Invalid'),
        firstName: Yup.string().required('You missed this! Fill in the required details to proceed.'),
        lastName: Yup.string().required('You missed this! Fill in the required details to proceed.'),
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const pgw = useSelector(state => state.pgw)
    const validation = Yup.object(initialValidation)

    useEffect(() => {
        let date = new Date().getFullYear().toString()
        date = date.substring(2)
        setInitialValidation({
            ...initialValidation,
            expiryYear: Yup.number().required('You missed this! Fill in the required details to proceed.').min(date, 'It appears that your card is expired. Please use a different card.').max(99, 'It appears that your card is expired. Please use a different card.'),
        })

        setMinimumYear(date)

    }, [])

    useEffect(() => {
        if (isPanelSelected) {
            if (formRef.current) {
                formRef.current.resetForm(formRef.current.initialValues)
            }
        }
    }, [isPanelSelected])


    const handleDateChange = (e, setFieldValue, setFieldError) => {
        setFieldValue('expiryDate', e.target.value)
        let values = e.target.value.split('/')
        values.forEach((date, i) => {
            if (i !== 1) {
                let newDate = date ? date : '13'
                setFieldValue('expiryMonth', parseInt(newDate))
            } else {
                let newDate = date ? date : '0'
                setFieldValue('expiryYear', parseInt(newDate))
                if (date === minimumYear) {
                    let month = new Date().getMonth() + 1
                    setInitialValidation({
                        ...initialValidation,
                        expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').min(month.toString(), 'It appears that your card is expired. Please use a different card.').max(12, 'It appears that your card is expired. Please use a different card.')
                    })
                } else {
                    setInitialValidation({
                        ...initialValidation,
                        expiryMonth: Yup.number().required('You missed this! Fill in the required details to proceed.').max(12, 'Invalid Month')
                    })
                }
            }
        })
    }

    const handleDateTouched = (errors, setFieldTouched) => {
        setFieldTouched('expiryDate', true)
    }

    const handleCardChange = (e, setFieldValue) => {
        setFieldValue("cardNumber",e.target.value)
        handleCheckCardScheme(e.target.value)
    }

    const handleCardTouched = (errors, setFieldTouched) => {
        setFieldTouched("cardNumber", true)
    }


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleCheckCardScheme = (value) => {
        if (value && value.length > 4) {
            value = value.substring(0, 4)
        }
        props.handleCardNumField(value)
    }

    const handleCardFieldMask = () => {
        setShowCardDetails(!showCardDetails)
    };

    const handleCvvFieldMask = () => {
        setShowCvvDetails(!showCvvDetails)
    };

    useEffect(() => {
        if (payment.merchantCustomerInformation && payment.merchantCustomerInformation.billingAddress) {
            setInitialForm({
                ...initialForm,
                line1: '',
                line2: '',
                city: '',
                state: '',
                zipCode: '',
                country: 'PH'
            })
            setInitialValidation({
                ...initialValidation,
                line1: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                line2: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                city: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                state: Yup.string().required('You missed this! Fill in the required details to proceed.'),
                country: Yup.string().required('You missed this! Fill in the required details to proceed.')
            })
        }
    }, [payment])

    return (
        <div className={classes.root}>
            <Formik
                initialValues={initialForm}
                validationSchema={validation}
                innerRef={formRef}
            >
                {({
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                    values,
                    errors,
                    touched,
                    isValid
                }) => (
                    <Form>
                        <FormObserver handleFormChange={props.handleFormChange} valuestest={values}></FormObserver>
                        <Grid
                            container
                            spacing={3}
                            id='grid-container-pgw-form'
                        >
                            <Grid item xs={12} sm={12} md={12} id='grid-item-card-number'>
                                <Field id='field-card-number' name='cardNumber'>
                                    {(fields) => (
                                        <InputMask
                                            id='inputmask-card-number'
                                            inputProps={{ inputMode: 'numeric' }}
                                            mask="9999 9999 9999 9999"
                                            // onChange={handleChange('cardNumber')}
                                            onChange={(e) => handleCardChange(e, setFieldValue)}
                                            onBlur={() => handleCardTouched(errors, setFieldTouched)}
                                            value={values.cardNumber}
                                            maskPlaceholder={""}
                                            disabled={disabled}
                                            name='cardNumber'
                                        >
                                            <TextField
                                                id='textfield-card-number'
                                                type={showCardDetails ? 'text' : 'password'}
                                                variant="outlined"
                                                name='cardNumber'
                                                fullWidth
                                                autoComplete='off'
                                                placeholder="16-digit Card Number"
                                                error={errors.cardNumber && touched.cardNumber && true}
                                                helperText={errors.cardNumber && touched.cardNumber && errors.cardNumber}
                                                InputProps={{
                                                    classes: { input: classes.cardFormFields },
                                                    endAdornment: (
                                                        <InputAdornment 
                                                            id='end-adornment-card-number' 
                                                            position="end"
                                                        >
                                                            {props.cardLogo && showCardDetails ?
                                                                <img src={props.cardLogo} className={classes.cardIcons} />
                                                                : <></>
                                                            }
                                                            {props.cardLogo ? initialForm.isCardValid = true : initialForm.isCardValid = false}
                                                            <IconButton
                                                                id='button-card-number-mask'
                                                                size={'small'}
                                                                onClick={handleCardFieldMask}
                                                            >
                                                                {showCardDetails ? 
                                                                    <Visibility 
                                                                        id='button-show-card-number' 
                                                                        className={classes.maskButton} 
                                                                    /> 
                                                                    : 
                                                                    <VisibilityOff 
                                                                        id='button-hide-card-number' 
                                                                        className={classes.maskButton} 
                                                                    />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </InputMask>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Field name='expiryDate'>
                                    {(fields) => (
                                        <InputMask
                                            mask="99/99"
                                            inputProps={{ inputMode: 'numeric' }}
                                            maskPlaceholder={null}
                                            onChange={e => handleDateChange(e, setFieldValue, setFieldError)}
                                            onBlur={() => handleDateTouched(errors, setFieldTouched)}
                                            value={values.expiryDate}
                                            disabled={disabled}
                                            InputProps={{ classes: { input: classes.cardFormFields } }}
                                        >
                                            <TextField
                                                variant="outlined"
                                                name='expiryDate'
                                                fullWidth
                                                autoComplete='off'
                                                placeholder="Expiry (MM/YY)"
                                                error={(errors.expiryDate || errors.expiryMonth || errors.expiryYear) && touched.expiryDate && true}
                                                helperText={
                                                    (errors.expiryDate || errors.expiryMonth || errors.expiryYear) && touched.expiryDate && (errors.expiryDate || errors.expiryMonth || errors.expiryYear)
                                                }
                                            />
                                        </InputMask>
                                    )}
                                </Field>
                                <input type='hidden' name='expirtMonth' value={values.expiryMonth}></input>
                                <input type='hidden' name='expirtYear' value={values.expiryYear}></input>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} id='grid-item-cvc'>
                                <Field id='field-cvc' name='cvc'>
                                    {(fields) => (
                                        <InputMask
                                            id='input-mask-cvc'
                                            mask="999"
                                            inputProps={{ inputMode: 'numeric' }}
                                            onChange={handleChange('cvc')}
                                            onBlur={handleBlur('cvc')}
                                            value={values.cvc}
                                            maskPlaceholder={null}
                                            disabled={disabled}
                                        >
                                            <TextField
                                                id='text-field-cvc'
                                                type={showCvvDetails ? 'text' : 'password'}
                                                variant="outlined"
                                                name='cvc'
                                                fullWidth
                                                placeholder="CVV/CVC"
                                                autoComplete='off'
                                                error={errors.cvc && touched.cvc}
                                                helperText={errors.cvc && touched.cvc && errors.cvc}
                                                InputProps={{
                                                    classes: { input: classes.cardFormFields },
                                                    startAdornment: (
                                                        <InputAdornment 
                                                            id='start-adornment-cvc' 
                                                            position="start"
                                                        >
                                                            <Info
                                                                id='info-cvc'
                                                                color='initial'
                                                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                aria-haspopup="true"
                                                                onMouseEnter={handlePopoverOpen}
                                                                onMouseLeave={handlePopoverClose}
                                                                className={classes.popoverIcon}
                                                            ></Info>
                                                            <Popover
                                                                id="mouse-over-popover"
                                                                className={classes.popover}
                                                                classes={{
                                                                    paper: classes.paper,
                                                                }}
                                                                open={open}
                                                                anchorEl={anchorEl}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                onClose={handlePopoverClose}
                                                                disableRestoreFocus
                                                            >
                                                                <Typography id= 'typography-cvc' variant='caption'>You can find this 3 or 4 digit code at the back of your card, beside your signature.</Typography>
                                                            </Popover>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment 
                                                            id='end-adornment-cvc' 
                                                            position="end"
                                                        >
                                                            <IconButton
                                                                id='button-cvc-mask'
                                                                size={'small'}
                                                                onClick={handleCvvFieldMask}
                                                            >
                                                                {showCvvDetails ?  
                                                                    <Visibility 
                                                                        id='button-show-cvc' 
                                                                        className={classes.maskButton} 
                                                                    /> 
                                                                    : 
                                                                    <VisibilityOff 
                                                                        id='button-hide-cvc' 
                                                                        className={classes.maskButton} 
                                                                    />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </InputMask>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Field
                                    name='firstName'
                                    variant="outlined"
                                    component={formikTextField}
                                    value={values.firstName}
                                    placeholder="First Name"
                                    fullWidth
                                    disabled={disabled}
                                    InputProps={{ classes: { input: classes.cardFormFields } }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Field
                                    name='lastName'
                                    variant="outlined"
                                    component={formikTextField}
                                    value={values.lastName}
                                    placeholder="Last Name"
                                    fullWidth
                                    disabled={disabled}
                                    InputProps={{ classes: { input: classes.cardFormFields } }}
                                />
                            </Grid>
                            {

                                payment.merchantCustomerInformation && payment.merchantCustomerInformation.billingAddress ?
                                    <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Field
                                                name='line1'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.line1}
                                                placeholder="House/Unit No., Street"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='line2'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.line2}
                                                placeholder="Subdivision, Barangay"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='city'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.city}
                                                placeholder="City"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='state'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.state}
                                                placeholder="Province"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Field
                                                name='country'
                                                variant="outlined"
                                                component={formikTextField}
                                                value={values.country}
                                                placeholder="Country"
                                                fullWidth
                                                disabled={true}
                                                InputProps={{ classes: { input: classes.cardFormFields } }}
                                            />
                                        </Grid>
                                    </>
                                    : <> </>
                            }


                        </Grid>
                    </Form>
                )}
            </Formik>
        </div >
    );
}

export default PgwForm;