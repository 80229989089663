import { terminateSession } from "../../utils/Auth";
import {
    GET_AUTH,
    REMOVE_AUTH,
    TERMINATE_SESSION
} from "./AuthTypes";

export const requestAuth = (payload) => {
    return {
        type: GET_AUTH,
        payload
    };
}

export const terminate = () => {
    terminateSession();
    return {
        type: TERMINATE_SESSION,
        payload: null
    };
}


export const removeAuth = () => {
    return {
        type: REMOVE_AUTH
    }
}